export const enum CompletionType {
  empty = '',
  all = 'all',
  any = 'any',
}

export const enum CommunicationType {
  email = 'email',
  slack = 'slack',
  mobile = 'mobile',
  microsoft = 'microsoft',
}

export const enum ImportVendor {
  slack = 'slack',
  microsoft = 'microsoft',
  bamboohr = 'bamboo',
}

export const enum IntegrationType {
  inbound = 'inbound',
  outbound = 'outbound',
}

export const enum StepFlow {
  user = 'user',
  org = 'org',
}

export const enum Operator {
  lessEqual = '<=',
  greaterEqual = '>=',
  range = 'range',
  contains = 'match',
}

export enum FieldType {
  plain_text_input_multiline = 'plain_text_input_multiline',
  plain_text_input = 'plain_text_input',
  datepicker = 'datepicker',
  datetimepicker = 'datetimepicker',
  file_attachment = 'file_attachment',
  multifile_attachment = 'multifile_attachment',
  dropdown = 'dropdown',
  multiselect = 'multi_static_select',
  watchers = 'watchers',
  amount = 'amount',
  amount_crypto = 'amount_crypto',
  number = 'number',
  user_select = 'user_select',
  multi_users_select = 'multi_users_select',
  department = 'department',
  location = 'location',
  contract_type = 'contract_type',
  radio_buttons = 'radio_buttons',
  //
  accounting_vendors = 'accounting_vendors',
  accounting_customer = 'accounting_customer',
  accounting_category = 'accounting_category',
  accounting_account = 'accounting_account',
  accounting_product = 'accounting_product',
  accounting_tax = 'accounting_tax',
  accounting_multi_vendors = 'accounting_multi_vendors',
  line_items = 'line_items',
  item_price = 'item_price',
  item_price_crypto = 'item_price_crypto',
  quantity = 'quantity',
  tax_rate = 'tax_rate',
  total = 'total',
  currency = 'currency',
  cost_center = 'cost_center',
  crypto_currency = 'crypto_currency',
  // SoftLedger fields
  softledger_currency = 'softledger_currency',
  softledger_location = 'softledger_location',
  softledger_tax = 'softledger_tax',
  softledger_ledger_account = 'softledger_ledger_account',
  softledger_vendor = 'softledger_vendor',
  softledger_product = 'softledger_product',
  softledger_cost_center = 'softledger_cost_center',
  softledger_job = 'softledger_job',
  softledger_custom = 'softledger_custom',
  // xero fields
  xero_tax_rate = 'xero_tax_rate',
  xero_account = 'xero_account',
  xero_item = 'xero_item',
  xero_custom = 'xero_custom',
  // qbo fields
  quickbooks_item = 'quickbooks_item',
  quickbooks_custom = 'quickbooks_custom',
}

export const enum Status {
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
  canceled = 'canceled',
  deferred = 'deferred',
  scheduled = 'scheduled',
  change_requested = 'change_requested',
  not_required = 'not required',
}

export const enum PaymentStatus {
  paid = 'paid',
  unpaid = 'unpaid',
}

export const enum PurchaseOrderStatus {
  draft = 'draft',
  approved = 'approved',
  billed = 'billed',
  canceled = 'canceled',
  closed = 'closed',
}

export const enum QuoteStatus {
  draft = 'draft',
  approved = 'approved',
  canceled = 'canceled',
  invoiced = 'invoiced',
  closed = 'closed',
}

export const enum RFQStatus {
  expired = 'expired',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  canceled = 'canceled',
  change_requested = 'change_requested',
  await_processing = 'await_processing',
  processed = 'processed',
}

export const enum ProposalStatus {
  requested = 'requested',
  proposed = 'proposed',
  expired = 'expired',
  accepted = 'accepted',
  rejected = 'rejected',
}

export const enum ApprovalRequestActions {
  approve = 'approve',
  cancel = 'cancel',
  reject = 'reject',
  requestChanges = 'change-request',
}

export const enum Role {
  admin = 'Admin',
  requestor = 'Requestor',
  vendor = 'Vendor',
}

export enum Channel {
  slack = CommunicationType.slack,
  microsoft = CommunicationType.microsoft,
}

export const enum PaywallPopupTypes {
  general = 'general',
}

export const enum Frequency {
  daily = 'daily',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum IntegrationsState {
  connected = 'connected',
  not_connected = 'not_connected',
}

export enum WorkflowType {
  custom = 'custom',
  access = 'access',
  vendor = 'vendor_approval',
  invoice = 'invoice',
  bill = 'bill',
  quote = 'quote',
  purchaseOrder = 'purchase_order',
  xeroPO = 'xero_po',
  xeroBill = 'xero_bill',
  xeroInvoice = 'xero_invoice',
  xeroQuote = 'xero_quote',
  qboPO = 'quickbooks_po',
  qboBill = 'quickbooks_bill',
  softledgerBill = 'softledger_bill',
  contract = 'contract',
  timeOff = 'time_off',
  vendor_onboarding = 'vendor_onboarding',
  requestForQuote = 'request_for_quote',
}

export const enum AccountingIntegrationVendor {
  quickbooks = 'quickbooks',
  softledger = 'softledger',
  xero = 'xero',
}

export const enum EmailRequestsAvailability {
  private = 'private',
  public = 'public',
}

export { Currencies } from './currency'

export const enum AccessAction {
  grant = 'grant',
  restrict = 'restrict',
}

export const enum Plan {
  free = 'free',
  basic = 'basic',
  pro = 'pro',
  enterprise = 'enterprise',
  custom = 'custom',
  pilot = 'pilot',
  proPlus = 'pro plus',
  starter = 'starter',
  easyPeasy = 'easy peasy',
}

export const enum Priority {
  lowest = 'lowest',
  low = 'low',
  medium = 'medium',
  high = 'high',
  critical = 'critical',
}

export const enum FormType {
  custom = 'custom',
  po = 'purchase_order',
  bill = 'bill',
  invoice = 'invoice',
  vendor = 'vendor',
}

export enum Language {
  ENG = 'ENG',
  RUS = 'RUS',
}

export enum FileType {
  csv = 'csv',
  xlsx = 'xlsx',
}

export enum TaskStatus {
  todo = 'todo',
  done = 'done',
  canceled = 'canceled',
  in_progress = 'in_progress',
}

export enum KeyboardKeys {
  BACKSPACE = 'Backspace',
  ENTER = 'Enter',
  SPACE = ' ',
  TAB = 'Tab',
  COMMA = ',',
}

export enum ReceiveNotificationType {
  all = 'all',
  mentionsOnly = 'mentioned',
}

export enum ContactType {
  quote = 'quote',
  featureSuggestion = 'feature_suggestion',
}

export enum TimeOffDuration {
  day = 'day',
  hour = 'hour',
}

export enum TimeFormatType {
  date = 'date',
  datetime = 'datetime',
}

export enum Environment {
  dev = 'dev',
  staging = 'staging',
  production = 'production',
  local = 'local',
}

export enum PipelineStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum NavigateDirection {
  left = 'left',
  right = 'right',
}
